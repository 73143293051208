import { useState } from 'react'
import PropTypes from 'prop-types'
import './WorkspacePicker.scss'

export const WorkspacePicker = ({ workspaces, selectedWorkspaceId, onSelectWorkspace }) => {
  return (
    <div className="d-flex flex-column w-100 ws-picker">
      {workspaces.map((item) => (
        <div
          className={`mx-2 my-1 ws-picker__item${
            item.id === selectedWorkspaceId ? '--active' : ''
          }`}
          key={item.id}
          onClick={() => onSelectWorkspace(item)}
        >
          {item.name}
        </div>
      ))}
      {workspaces.length === 0 && (
        <h6 className="text-muted text-center my-2">No available workspaces</h6>
      )}
    </div>
  )
}

WorkspacePicker.propTypes = {
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  selectedWorkspaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelectWorkspace: PropTypes.func,
}

WorkspacePicker.defaultProps = {
  workspaces: [],
  onSelectWorkspace: () => {},
}
