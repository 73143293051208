import { Container, Row, Col } from 'react-bootstrap'
import { StepHeader } from '../StepHeader/StepHeader'
import { StepperComponent } from '../StepperComponent/StepperComponent'
import { TextField, Button } from 'monday-ui-react-core'
import { getRandomString } from '../../utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Check, CloseSmall } from 'monday-ui-react-core/icons'
import { useNavigate } from 'react-router-dom'
import { BACKEND_URL } from '../../config'
import { useLocalStorage } from '../../Hooks/useLocalStorage'

export const Step2 = () => {
  const baseUrlId = useMemo(() => getRandomString(), [])
  const clientIdId = useMemo(() => getRandomString(), [])
  const clientSecretId = useMemo(() => getRandomString(), [])

  const [mondayApiKey] = useLocalStorage('mondayApiKey', '')

  const [baseUrl, setBaseUrl] = useState('')
  const [clientId, setClientId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const navigate = useNavigate()

  const inputs = [
    {
      id: baseUrlId,
      label: 'Marketo Base URL',
      value: baseUrl,
      setValue: setBaseUrl,
      type: TextField.types.TEXT,
      placeholder: 'https://<company>.marketo.com',
    },
    {
      id: clientIdId,
      label: 'Marketo Client ID',
      value: clientId,
      setValue: setClientId,
    },
    {
      id: clientSecretId,
      label: 'Marketo Client Secret',
      value: clientSecret,
      setValue: setClientSecret,
    },
  ]

  const [apiKeyStatus, setApiKeyStatus] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const search = window.location.search
  const params = new URLSearchParams(search)

  const getUserPermissions = async () => {
    try {
      const data = await fetch(`${BACKEND_URL}/checkBothTokens`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then(async (data) => {
        const resp = await data.json()
        if (resp.success) {
          navigate('/step3')
        }
        return resp.success
      })
      return data
    } catch (error) {
      console.log('Connection error', error)
    }
  }

  useEffect(() => {
    const reload = params.get('reload')
    if (reload) {
      getUserPermissions()
    }
  }, [])

  const inputIcon = useMemo(() => {
    if (apiKeyStatus === 'success') {
      return Check
    }
    if (apiKeyStatus === 'error') {
      return CloseSmall
    }
    return undefined
  }, [apiKeyStatus])

  const statusText = useMemo(() => {
    if (apiKeyStatus === 'success') {
      return 'Success'
    }
    if (apiKeyStatus === 'error') {
      return 'Authentication Error'
    }
    return ''
  }, [apiKeyStatus])

  const submitApiKey = useCallback(
    async (e) => {
      let status = 'error'

      setApiKeyStatus('')
      setSubmitting(true)

      try {
        const data = await fetch(
          `${BACKEND_URL}/validateMarketoToken?${new URLSearchParams({
            baseUrl,
            clientId,
            clientSecret,
          })}`,
          {
            credentials: 'include',
          },
        )
        if (!data.ok) {
          status = 'error'
        } else {
          status = 'success'
        }
      } catch (error) {
        status = 'error'
      } finally {
        setSubmitting(false)
        setApiKeyStatus(status)
      }
    },
    [baseUrl, clientId, clientSecret],
  )

  const saveCredentialsAndContinue = useCallback(async () => {
    try {
      setSubmitting(true)
      const resp = await fetch(`${BACKEND_URL}/saveCredentials`, {
        method: 'POST',
        body: JSON.stringify({
          mondayApiKey: localStorage.getItem('MondayApiKey'),
          marketoBaseUrl: baseUrl,
          marketoClientId: clientId,
          marketoClientSecret: clientSecret,
        }),
        headers: { 'Content-Type': 'application/json' },
      })
      if (resp.ok) {
        window.location.assign(`${window.location.href}?reload=${true}`)
      }
    } catch (error) {
      console.error('Failed to save credentials', error)
    } finally {
      setSubmitting(false)
    }
  }, [mondayApiKey, baseUrl, clientId, clientSecret, navigate])

  return (
    <Container>
      <Row>
        <StepHeader />
      </Row>
      <Row>
        <Col>
          <StepperComponent currentStep={2} />
        </Col>
      </Row>

      {inputs.map((item) => (
        <Row key={item.id} className={'mb-3'}>
          <Col xs={1} md={2} />
          <Col xs={5} md={3}>
            <label className="d-block my-2" htmlFor={item.id}>
              {item.label}
            </label>
          </Col>
          <Col xs={5} className={'d-flex align-items-center'}>
            <TextField
              disabled={submitting}
              id={item.id}
              className={'w-100'}
              placeholder={item.placeholder || '****************'}
              type={item.type || TextField.types.PASSWORD}
              size={TextField.sizes.MEDIUM}
              value={item.value}
              onChange={(e) => item.setValue(e)}
              validation={{
                status: apiKeyStatus,
              }}
              iconName={inputIcon}
            />
          </Col>
        </Row>
      ))}

      <Row></Row>
      <Row className="mt-3">
        <Col xs={6} md={5} />
        <Col
          xs={5}
          className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start"
        >
          <Button
            size={Button.sizes.SMALL}
            onClick={(e) => submitApiKey(e)}
            kind={Button.kinds.SECONDARY}
            loading={submitting}
            disabled={submitting}
            className="me-3"
          >
            Test Connection
          </Button>
          <span>{statusText}</span>
        </Col>
      </Row>
      <Row className={'mt-5'}>
        <Col xs={10} className={'d-flex justify-content-end'}>
          <Button
            kind={Button.kinds.TERTIARY}
            className={'me-4'}
            onClick={() => {
              navigate('/step1')
            }}
          >
            <b>Back</b>
          </Button>
          <Button
            disabled={submitting || apiKeyStatus !== 'success'}
            onClick={async () => {
              await saveCredentialsAndContinue()
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
