import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import './HowTo.css'
import { StepComponent } from '../../Components/StepComponent/StepComponent'
import { StepHeader } from '../StepHeader/StepHeader'

export const HowToPage = () => {
  const getText = (index) => {
    switch (index) {
      case 1:
        return 'If you’re tired of juggling multiple platforms for your email marketing efforts, our integration is here to make your life easier.With just a few clicks, you can easily create and sync emails and email campaign results between monday.com and Marketo, streamlining your workflow and saving your time and effort. \n This integration allows users to create and continuously/seamlessly synchronize email jobs between monday.com and Marketo. Once a email job is created in monday.com the integration creates the same email job object in Marketo inside the specified Email Program, with the specified Email Template and connected to the specified Marketo Smart Campaign. Once the Email Job is created, the synchronization will continuously fetch email campaign results and show them in monday.com.'
      case 2:
        return "Welcome to our tutorial on setting up a synchronization between monday.com and Marketo. \nIn this tutorial, we will be guiding you through a step-by-step wizard to connect your monday.com and Marketo accounts, select a workspace, and initialize the synchronization process. To make the process easier, we have created a video that will walk you through each step of the wizard. \nTo get started, simply follow the instructions in the video and you'll have your synchronization set up in no time! So, please make sure to watch the video carefully and have your monday.com and Marketo login credentials ready. If you have any questions or encounter any issues during the process, please don't hesitate to reach out to our support team for assistance."
      case 3:
        return 'To seamlessly sync your email from a monday.com board to your Marketo instance, simply follow these easy steps in the application wizard:\n1. Connect to monday.com by clicking the “Authorize” button and granting access.\n 2. Next, connect to Marketo by entering your Client ID, Client Secret, and Base URL.\n 3. Select the monday.com workspace where you want to create the email board and sync it with emails in Marketo.\n 4. Finally, initiate the workspace synchronization to streamline your process.\nBy following these steps, you can ensure that your email communication is synced and organized for optimal efficiency.'
      default:
        break
    }
  }
  return (
    <Container className="home-container">
      <StepHeader />
      <Col xs={12} lg={2} xl={2} />
      <Row className="mt-5">
        {[...Array(2)].map((item, index) => (
          <>
            <Col xs={12} lg={6} xl={6} className="mb-2">
              <StepComponent index={index + 1} text1={getText(index + 1)} />
            </Col>
          </>
        ))}
      </Row>
      <Col xs={12} lg={2} xl={2} />
      <Row className="mt-5">
        {[...Array(2)].map((item, index) => (
          <>
            <Col xs={12} lg={6} xl={6} className="mb-2">
              <StepComponent index={index + 3} text1={getText(index + 3)} />
            </Col>
          </>
        ))}
      </Row>
    </Container>
  )
}
