import { Container, Row, Col } from 'react-bootstrap'
import { StepHeader } from '../StepHeader/StepHeader'
import { StepperComponent } from '../StepperComponent/StepperComponent'
import { Button } from 'monday-ui-react-core'
import { getRandomString } from '../../utils'
import { useEffect, useMemo, useState } from 'react'
import { Check, CloseSmall } from 'monday-ui-react-core/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { BACKEND_URL, OAUTH_CLIENT_ID } from '../../config'
import { useLocalStorage } from '../../Hooks/useLocalStorage'
import Axios from 'axios'

export const Step1 = () => {
  const mondayFieldId = useMemo(() => getRandomString(), [])
  const navigate = useNavigate()

  const [apiKey, setApiKey] = useState('')
  const [apiKeyStatus, setApiKeyStatus] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [, setUserMondayAPIKey] = useLocalStorage('mondayApiKey', '')

  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    const code = params.get('code')
    if (code) {
      setSubmitting(true)
      Axios({
        url: `${BACKEND_URL}/getMondayAccessToken/`,
        method: 'POST',
        withCredentials: true,
        data: {
          code,
        },
      })
        .then((response) => {
          if (response.data) {
            setApiKey(response.data)
            setUserMondayAPIKey(response.data)
            setApiKeyStatus('success')
            navigate('/step2')
            localStorage.setItem('MondayApiKey', response.data)
            window.location.reload()
            // setMondayApiKey(response.data)
            // setUserAuthenticated(true)
          } else {
            // setUserAuthenticated(false)
            console.warn('Failed to get token from backend')
          }
        })
        .catch((error) => {
          console.warn(error)
        })
        .finally(() => setSubmitting(false))
    }
  }, [location, setUserMondayAPIKey, setApiKey])

  const inputIcon = useMemo(() => {
    if (apiKeyStatus === 'success') {
      return Check
    }
    if (apiKeyStatus === 'error') {
      return CloseSmall
    }
    return undefined
  }, [apiKeyStatus])

  const statusText = useMemo(() => {
    if (apiKeyStatus === 'success') {
      return 'Success'
    }
    if (apiKeyStatus === 'error') {
      return 'Authentication Error'
    }
    return ''
  }, [apiKeyStatus])

  // const submitApiKey = useCallback(async () => {
  //   let status = 'error'

  //   setApiKeyStatus('')
  //   setSubmitting(true)

  //   try {
  //     const data = await fetch(`${BACKEND_URL}/validateMondayToken`, {
  //       headers: {
  //         authorization: apiKey,
  //       },
  //     })
  //     if (data.ok) {
  //       status = 'success'
  //       setUserMondayAPIKey(apiKey)
  //     }
  //   } catch (error) {
  //     console.warn('Error', error)
  //   } finally {
  //     setSubmitting(false)
  //     setApiKeyStatus(status)
  //   }
  // }, [apiKey, setUserMondayAPIKey])

  return (
    <Container>
      <Row>
        <StepHeader />
      </Row>
      <Row>
        <Col>
          <StepperComponent currentStep={1} />
        </Col>
      </Row>

      <Row>
        <Col xs={1} md={2} />
        <Col xs={5} md={3}>
          <label className="d-block me-4 my-2 my-xs-0" htmlFor={mondayFieldId}>
            Monday authorization
          </label>
        </Col>
        <Col xs={5} className={'d-flex align-items-center'}>
          {apiKeyStatus !== 'success' ? (
            <a
              href={`https://auth.monday.com/oauth2/authorize?client_id=${OAUTH_CLIENT_ID}`}
              className="me-3"
            >
              <Button>Authenticate</Button>
            </a>
          ) : (
            <span>Authenticated</span>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={6} md={5} />
        <Col
          xs={5}
          md={5}
          className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start"
        ></Col>
      </Row>
      <Row className={'mt-5'}>
        <Col xs={10} className={'d-flex justify-content-end'}>
          <Button kind={Button.kinds.TERTIARY} className={'me-4'} onClick={() => navigate('/')}>
            <b>Back</b>
          </Button>
          <Button
            disabled={submitting || apiKeyStatus !== 'success'}
            onClick={() => {
              navigate('/step2')
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
