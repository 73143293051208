import PropTypes from 'prop-types'
import { forwardRef, Fragment, useMemo } from 'react'
import './StepperComponent.scss'

const SingleStep = forwardRef(({ index, title, description, status }, ref) => {
  const extraClass = useMemo(() => {
    switch (status) {
      case 'active':
        return '--active'
      case 'done':
        return '--done'
      default:
        return ''
    }
  }, [status])

  return (
    <div className={`d-flex mm-stepper__step${extraClass}`} ref={ref}>
      <div className="mm-stepper__index-container">
        <span className="mm-stepper__number me-3">{index}</span>
      </div>
      <div className="mm-stepper__container">
        <div className="d-block font-weight-bold" style={{ fontWeight: 'bold' }}>
          {title}
        </div>
        <span>{description}</span>
      </div>
    </div>
  )
})

SingleStep.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.oneOf(['active', 'done', 'inactive']),
}

const STEPS = [
  {
    index: 1,
    title: 'Step 1',
    description: 'Connect to Monday',
  },
  {
    index: 2,
    title: 'Step 2',
    description: 'Connect to Marketo',
  },
  {
    index: 3,
    title: 'Step 3',
    description: 'Choose Workspace',
  },
  {
    index: 4,
    title: 'Step 4',
    description: 'Initialize Workspace',
  },
]

export const StepperComponent = ({ currentStep }) => {
  const getStatus = (index) => {
    if (currentStep > index) {
      return 'done'
    }
    if (currentStep < index) {
      return 'inactive'
    }
    return 'active'
  }

  return (
    <div className="d-flex mx-4 mm-stepper">
      {STEPS.map((value) => (
        <Fragment key={value.index}>
          <SingleStep {...value} status={getStatus(value.index)} />
          {value.index !== STEPS.length && <p className="mm-stepper__spacer px-2"> </p>}
        </Fragment>
      ))}
    </div>
  )
}

StepperComponent.propTypes = {
  currentStep: PropTypes.number,
}

StepperComponent.defaultProps = {
  currentStep: 1,
}
