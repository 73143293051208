import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const ProtectedRoute = ({ children, isAuthenticated }) => {
  let location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to="/step1" state={{ from: location }} replace />
  }
  return children
}

export default ProtectedRoute
