import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Button } from 'monday-ui-react-core'
import './PrivacyPolicy.scss'
import { StepHeader } from '../StepHeader/StepHeader'
import { useNavigate } from 'react-router-dom'

export const PrivacyPolicy = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <StepHeader />
      </Row>
      <br />
      <br />
      <br />
      <h1>Privacy Policy</h1>
      <p>
        This privacy notice discloses the privacy practices for https://capabilitysource.com/ and
        services provided to our customers and partners. This privacy notice applies to the
        following: The personal identifiable information that is collected from you through the
        website, how it is used and with whom it is shared. The personal identifiable information
        that is collected upon project engagement as needed to support the needs of a customer. Your
        rights and choices available to you regarding the use of your data. The security procedures
        in place to protect the misuse of your information. How you can contact us regarding any
        questions or concerns regarding your data.
        <br />
        <br />
        DATA SECURITY
        <br />
        CapabilitySource is committed to ensuring that your information is secure. When you submit
        information via our website, it is protected and not shared online or offline with third
        parties. We take appropriate measures to protect Personal Data that include incident
        response identification, prioritization, communication, standard operating procedures,
        analysis, and remediation.
        <br />
        <br />
        INFORMATION COLLECTION, USE, AND SHARING
        <br />
        CapabilitySource Inc. is the owner of the information collected on this site. We only have
        access to/collect information that you voluntarily give us via email, electronic submission
        of our online form or this website, or other direct contact from you. We will not disclose,
        sell or rent this information to anyone. Should you choose to use our Contact online forms
        the information we collect is used for the purpose of understanding your business, your
        needs, and acquiring your contact information to respond accordingly. Please be mindful of
        the information you share and do not include any highly sensitive information. Upon
        submission of the form, the data entered is used to populate the body of an email received
        by solutions@capabilitysource.com . The information is not stored via other means. You may
        choose to share your information by sending an email directly to that address or contacting
        us by phone at (866) 406-2790. We may contact you via email or phone call based on your
        initial outreach to us for information or our services. You may choose to opt out of any
        future communications at any time by contacting us at privacy@capabilitysource.com We will
        use your information to respond to you, regarding the reason you contacted us. Your contact
        information collected, including name, email address and phone number is intended solely for
        CapabilitySource’s business use. Your information will be treated in confidence and it will
        not be disclosed to other parties. CapabilitySource does not knowingly collect Personal Data
        from children under the age of 16. If you are under the age of 16, do not submit any
        Personal Data through the online form services, nor contact us by email. We encourage
        parents and legal guardians to monitor their children’s Internet usage and to help enforce
        our Privacy Policy by instructing their children never to provide Personal Data on the
        Service without their permission. If you have reason to believe that a child under the age
        of 16 has provided Personal Data to CapabilitySource, please contact us at
        privacy@capabilitysource.com , and we will delete that information.
        <br />
        <br />
        COOKIES
        <br />
        “Cookies” are small pieces of data that can be stored by an Internet browser for visitor
        tracking purposes and interactions with a website. Cookies cannot access your hard drive or
        send information about your computer system. You have the choice to disable cookies. Your
        browser should give you options for preventing, accepting, or receiving notice of new
        cookies. You can configure your web-browser to disable cookies or if enabled, you may clear
        temporary internet file links.
        <br />
        <br />
        THIRD PARTY SITES
        <br />
        This website contains links to other sites. Please be aware that we are not responsible for
        the content or privacy practices of these other sites. We encourage our visitors to be aware
        of this when they leave our site, and to read the privacy statements of any other site that
        collects personally identifiable information.
        <br />
        <br />
        GENERAL DATA PROTECTION REGULATION (GDPR)
        <br />
        The EU General Data Protection Regulation (GDPR) replaces the Data Protection Directive
        95/46/EC and was designed to harmonize data privacy laws across Europe, to protect and
        empower all EU citizens data privacy and to reshape the way organizations across the region
        approach data privacy. CapabilitySource complies with all relevant data privacy requirements
        under EU General Data Protection Regulation (GDPR) regulations: CapabilitySource Compliance:
        Employee training on compliant security and privacy procedures Published Privacy Policies
        and Notices to inform customers of CapabilitySource compliance Privacy impact assessments
        Records of data processing activities Multi-factor access and role-based security controls
        <br />
        <br />
        INTERNATIONAL TRANSFER OF PERSONAL DATA
        <br />
        CapabilitySource is located in the United States. Based on your situational business needs
        and services provided by CapabilitySource, Personal Data may be collected, transferred, or
        processed by us in the United States or by our partners in other countries where they
        operate. Therefore, your Personal Data may be processed outside the European Economic Area
        (EEA), and in countries which are not subject to an adequacy decision by the European
        Commission and which may not provide for the same level of data protection as the EEA. Prior
        to any such engagement, we ensure that the recipient of your Personal Data offers an
        adequate level of protection, for instance by entering into standard contractual clauses for
        the transfer of data as approved by the European Commission (Art. 46 GDPR), or we will ask
        you for your prior consent to such international data transfers.
        <br />
        <br />
        YOUR RIGHTS AND CONTROL OVER INFORMATION
        <br />
        Under GDPR you have certain rights as a data subject to exercise with the company in
        relation to the Personal Data we hold. Depending on the applicable laws and, in particular,
        if you are located in the EEA or Switzerland or the United Kingdom, these rights may
        include: To access your Personal Data held by us (right to access); To rectify inaccurate
        Personal Data and, taking into account the purpose of processing the Personal Data, ensure
        it is complete (right to rectification); To erase/delete your Personal Data, to the extent
        permitted by applicable data protection laws (right to erasure; right to be forgotten)
        submit a Consumer Rights Management request; To restrict our processing of your Personal
        Data, to the extent permitted by law (right to restriction of processing); To transfer your
        Personal Data to another controller, to the extent possible (right to data portability); To
        object to any processing of your Personal Data carried out on the basis of our legitimate
        interests (right to object). Where we process your Personal Data for direct marketing
        purposes or share it with third parties for their own direct marketing purposes, you can
        exercise your right to object at any time to such processing without having to provide any
        specific reason for such objection; Not to be subject to a decision based solely on
        automated processing, including profiling, which produces legal effects (“Automated
        Decision-Making”). Automated Decision-Making currently does not take place on our websites;
        and To the extent we base the collection, processing and sharing of your Personal Data on
        your consent, to withdraw your consent at any time, without affecting the lawfulness of the
        processing based on such consent before its withdrawal.
        <br />
        <br />
        DATA RETENTION
        <br />
        CapabilitySource data retention is the duration of the contract or 60 days after the data
        deletion has been requested by a customer. Upon expiry of the applicable retention periods,
        your personal data will be deleted. If there is any data that we are unable, for technical
        reasons, to delete entirely from our systems, we establish appropriate measures to prevent
        any further use of such data.
        <br />
        <br />
        DATA PROTECTION OFFICER
        <br />
        CapabilitySource has appointed a chief privacy officer responsible for overseeing the
        implementation of its privacy program. If you wish to exercise your rights, or feel that we
        are not abiding by this privacy policy or have any concerns related to your information,
        please contact us at: CapabilitySource Inc. Attn: Data Protection Officer / Privacy Office
        8000 Fair Oaks Parkway, Building 3, Suite 3209 Fair Oaks Ranch, Texas 78015 USA Email:
        privacy@capabilitysource.com CapabilitySource may change our privacy policy over time by
        updating this page. This policy is effective as of July 31, 2019.
      </p>
    </Container>
  )
}
