import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

// import 'monday-ui-react-core/dist/main.css'
//Explore more Monday React Components here: https://style.monday.com/

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// import { Credentials } from './Screens/Credentials/Credentials'
import { Home } from './Screens/Home/Home'

import NavbarMenu from './Components/Navbar/Navbar'
import { useLocalStorage } from './Hooks/useLocalStorage'
import { Synchronize } from './Screens/Synchronize/Synchronize'
import { Step1 } from './Screens/Step1/Step1'
import { Button } from 'react-bootstrap'
import 'monday-ui-react-core/dist/main.css'
import './App.scss'
import { Step2 } from './Screens/Step2/Step2'
import { Step3 } from './Screens/Step3/Step3'
import { Step4 } from './Screens/Step4/Step4'
import { PrivacyPolicy } from './Screens/PrivacyPolicy/PrivacyPolicy'
import { TOS } from './Screens/TOS/TOS'
import { HowToPage } from './Screens/HowToGuide/HowTo'
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute'
import { BACKEND_URL } from './config'

function App() {
  // eslint-disable-next-line no-unused-vars
  const [userMondayAPIKey, setUserMondayAPIKey] = useLocalStorage('userMondayAPIKey', '')

  useEffect(() => {
    document.title = 'Marketo integrator'
  }, [])

  const [isEnabled, setIsEnabled] = useState(true)

  const getUserPermissions = async () => {
    try {
      const data = await fetch(`${BACKEND_URL}/checkBothTokens`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then(async (data) => {
        const resp = await data.json()
        setIsEnabled(resp.success)
        return resp.success
      })
      return data
    } catch (error) {
      console.log('Connection error', error)
    }
  }

  useEffect(() => {
    getUserPermissions()
  }, [])

  return (
    <div className="App">
      <div className="app_container w-lg-100">
        <Container fluid style={{ height: '100%', overflowY: 'auto' }} className="py-4">
          <Row className="mt-2">
            <Col lg={1}></Col>
            <Col xs={12} lg={10}>
              <Routes>
                <Route exact path="/*" element={<Home />} />
                <Route exact path="/step1" element={<Step1 />} />
                <Route exact path="/step2" element={<Step2 />} />
                <Route
                  exact
                  path="/step3"
                  element={
                    <ProtectedRoute isAuthenticated={isEnabled}>
                      <Step3 />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/step4"
                  element={
                    <ProtectedRoute isAuthenticated={isEnabled}>
                      <Step4 />
                    </ProtectedRoute>
                  }
                />
                <Route exact path="/terms-of-service" element={<TOS />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/how-to-guide" element={<HowToPage />} />
                {/* <Route exact path="/oauth" element={<Credentials />} /> */}
                {/* <Route exact path="/credentials" element={<Credentials />} /> */}
                {/* <Route exact path="/Synchronize" element={<Synchronize />} /> */}
              </Routes>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default App
