import { Container, Row, Col, Modal, Spinner } from 'react-bootstrap'
import { StepHeader } from '../StepHeader/StepHeader'
import { StepperComponent } from '../StepperComponent/StepperComponent'
import { Button, TextField } from 'monday-ui-react-core'
import { useNavigate } from 'react-router-dom'
import { WorkspacePicker } from '../../Components/WorkspacePicker/WorkspacePicker'
import { useCallback, useEffect, useRef, useState } from 'react'
import './Step3.scss'
import { BACKEND_URL } from '../../config'
import { useLocalStorage } from '../../Hooks/useLocalStorage'

export const Step3 = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const mondayApiKey = localStorage.getItem('MondayApiKey')
  const [selectedWorkspace, setSelectedWorkspace] = useLocalStorage('selectedWorkspace', '')
  const [currentStep, setCurrentStep] = useLocalStorage('currentStep')
  const [workspaces, setWorkspaces] = useState([])

  useEffect(() => {
    setSelectedWorkspace()
  }, [setSelectedWorkspace])

  const [workspaceName, setWorkspaceName] = useState('')

  const fetchData = useCallback(async () => {
    try {
      const data = await fetch(`${BACKEND_URL}/getAllWorkspaces`, {
        headers: {
          authorization: mondayApiKey,
        },
        method: 'GET',
      })
      if (data.ok) {
        setWorkspaces(await data.json())
      } else {
        console.error('failed to get data', data)
      }
    } catch (error) {
      console.error('Failed to contact server', error)
    } finally {
      setLoading(false)
    }
  }, [mondayApiKey])

  const createNewMondayWorkspace = useCallback(async () => {
    if (!mondayApiKey) {
      return
    }
    try {
      const data = await fetch(`${BACKEND_URL}/createMondayWorkspace`, {
        headers: {
          authorization: mondayApiKey,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          workspaceName,
          workspaceDescription: 'Created from integrator',
          workspaceKind: '',
        }),
      })
      if (data.ok) {
        const createdWorkspace = await data.json()
        setSelectedWorkspace(createdWorkspace)
        setWorkspaces((oldWorkspaces) => [
          createdWorkspace,
          ...oldWorkspaces.filter((w) => w.id !== createNewMondayWorkspace.id),
        ])
      } else {
        console.error('failed to get data', data)
      }
    } catch (error) {
      console.error('Failed to contact server', error)
    } finally {
      setLoading(false)
    }
  }, [mondayApiKey, workspaceName])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const [modalShown, setModalShown] = useState(false)
  const modalRef = useRef()

  return (
    <>
      <Container>
        <Row>
          <StepHeader />
        </Row>
        <Row>
          <Col>
            <StepperComponent currentStep={3} />
          </Col>
        </Row>

        {loading ? (
          <div className="d-flex w-100 justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Row className={'mb-3'}>
              <Col xs={0} md={2} />
              <Col xs={12} md={3}>
                <label className="d-block my-2">Choose a workspace</label>
              </Col>
              <Col xs={12} md={5}>
                <WorkspacePicker
                  workspaces={workspaces}
                  selectedWorkspaceId={selectedWorkspace?.id}
                  onSelectWorkspace={setSelectedWorkspace}
                ></WorkspacePicker>
              </Col>
            </Row>
            <Row></Row>
            <Row className="mt-1">
              <Col xs={5} />
              <Col xs={5}>
                <Button
                  className={'text-primary'}
                  kind={Button.kinds.TERTIARY}
                  onClick={() => setModalShown(true)}
                >
                  Create Workspace
                </Button>
              </Col>
            </Row>
            <Row className={'mt-5'}>
              <Col xs={10} className={'d-flex justify-content-end'}>
                <Button
                  kind={Button.kinds.TERTIARY}
                  className={'me-4'}
                  onClick={() => {
                    navigate('/step2')
                  }}
                >
                  <b>Back</b>
                </Button>
                <Button disabled={!selectedWorkspace} onClick={() => navigate('/step4')}>
                  Next
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Modal show={modalShown} centered ref={modalRef}>
        <Modal.Body>
          <Container className="p-3 p-md-5">
            <Row>
              <Col>
                <h4>
                  <b>Create workspace</b>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Enter the name for a new Workspace in Monday</p>
              </Col>
            </Row>
            <Row className="my-3">
              <Col xs={12} md={4} className="d-flex align-items-center">
                <label>Workspace name</label>
              </Col>
              <Col xs={12} md={8}>
                <TextField
                  className={'w-100'}
                  size={TextField.sizes.MEDIUM}
                  value={workspaceName}
                  onChange={(e) => setWorkspaceName(e)}
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col className="d-flex justify-content-end">
                <Button
                  kind={Button.kinds.TERTIARY}
                  className={'me-4'}
                  onClick={() => setModalShown(false)}
                >
                  <b>Cancel</b>
                </Button>
                <Button
                  className="px-4 ms-3"
                  onClick={async () => {
                    setModalShown(false)
                    try {
                      setLoading(true)
                      await createNewMondayWorkspace()
                    } catch (error) {
                      console.error('failed to create and fetch workspaces', error)
                    } finally {
                      setLoading(false)
                    }
                  }}
                >
                  <span className="mx-3">Create</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}
