import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Button } from 'monday-ui-react-core'
import './Home.scss'
import { StepHeader } from '../StepHeader/StepHeader'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { BACKEND_URL } from '../../config'

export const Home = () => {
  const navigate = useNavigate()

  const getUserPermissions = async () => {
    try {
      const data = await fetch(`${BACKEND_URL}/checkBothTokens`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then(async (data) => {
        const resp = await data.json()
        if (resp.success) {
          localStorage.setItem('MondayApiKey', resp.mondayApiKey)
          navigate('/step3')
        }
        return resp.success
      })
      return data
    } catch (error) {
      console.log('Connection error', error)
    }
  }

  useEffect(() => {
    getUserPermissions()
  }, [])

  return (
    <Container className="home-container">
      <Row>
        <StepHeader />
      </Row>

      <Row className="mt-5">
        <Col xs={0} xl={1} />
        <Col xs={12} lg={6} xl={6} className="mb-2">
          <div>
            This integration allows users to create and continuously/seamlessly synchronize email
            jobs between Monday.com and Marketo. Once a email job is created in Monday.com the
            integration creates the same email job object in Marketo inside the specified Email
            Program, with the specified Email Template and connected to the specified Marketo Smart
            Campaign. Once the Email Job is created, the synchronization will continuously fetch
            email campaign results and show them in Monday.com. The integration is configured via a
            step-driven easy setup wizard, which walks a user through authentication between each
            system.
          </div>
          <div className="d-flex flex-column flex-lg-row align-items-center my-4">
            <div className="d-flex flex-column align-items-center ">
              <img
                className="d-inline-block"
                alt="monday.com logo"
                src="/logo-monday@2x.png"
                style={{
                  height: '3.5rem',
                }}
              />
              <div
                style={{
                  width: '1px',
                  height: '4rem',
                  border: '5px solid #D3D3D3',
                  borderRadius: '1rem',
                }}
              />
              <img
                className="d-inline-block"
                alt="Marketo logo"
                src="/logo-marketo@2x.png"
                style={{
                  height: '3.5rem',
                }}
              />
            </div>
            <div>
              <ul className="home-container__list">
                <li>Connect monday.com workspace with existing Adobe Marketo workspace</li>
                <li>Create and manage email jobs from Marketo inside the monday.com board</li>
                <li>
                  Synchronize and track campaigns results inside monday.com for e-mail campaigns in
                  Marketo
                </li>
              </ul>
            </div>
          </div>
          <div>
            Once the setup is complete, the integration works in the background to synchronize email
            jobs. At this time, email jobs are synched along with their campaign results, email
            template , email program, smart campaign and other status information.
          </div>
        </Col>
        <Col xs={12} lg={6} xl={4}>
          <div className="home-container__powered-by ">
            <div className="d-flex align-items-center p-4 home-container__powered-by__logo">
              <img
                className="d-inline-block me-3"
                alt="Marketo Integrator logo"
                src="/cs-logo-icon@2x.png"
                style={{
                  height: '3.5rem',
                }}
              />
              <div className="d-inline-flex flex-column">
                <span className="home-container__powered-by__logo__subheader">Provided By</span>
                <span className="d-inline-block m-0 home-container__powered-by__logo__header">
                  <b>CapabilitySource</b>
                </span>
              </div>
            </div>
            <div className="p-4">
              <div>
                CapabilitySource develops digital marketing strategies and improves marketing
                operations capabilities that support the transformation journey and improve overall
                marketing maturity.
              </div>
              <h5 className="mt-5 d-inline-block">
                <b>Contact Us</b>
              </h5>
              <div className="d-flex flex-column">
                <span>CapabilitySource</span>
                <a href="mailto:info@capabilitysource.com">info@capabilitysource.com</a>
                <a href="https://www.capabilitysource.com" target="_blank" rel="noreferrer">
                  https://www.capabilitysource.com
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={0} xl={1} />
        <Col>
          <Button className="px-4 mt-5" onClick={() => navigate('/step1')}>
            Begin setup
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
