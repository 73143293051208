import { Button } from 'monday-ui-react-core'
import { Container, Nav, Navbar } from 'react-bootstrap'
import './StepHeader.scss'

export const StepHeader = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <div className="d-flex align-items-center logo-container">
            <img
              className="d-inline-block me-3"
              alt="Marketo Integrator logo"
              src="/cs-logo-icon@2x.png"
              style={{
                height: '3.5rem',
              }}
            />
            <div className="d-inline-flex flex-column">
              <h3
                className="d-inline-block m-0 logo-container__header"
                style={{
                  lineHeight: '1.4rem',
                }}
              >
                <b>Marketo Connector</b>
              </h3>
              <span
                style={{
                  lineHeight: '1.4rem',
                }}
                className="logo-container__subheader"
              >
                By CapabilitySource
              </span>
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link href="/privacy-policy">Privacy policy</Nav.Link>
            <Nav.Link href="/terms-of-service">Terms of service</Nav.Link>
            <Nav.Link href="/">About</Nav.Link>
            <Nav.Link href="/">Contact Us</Nav.Link>
            <Nav.Link href="/how-to-guide">How To Guide</Nav.Link>
            <Nav.Link
              onClick={() => {
                window.open('https://support.capabilitysource.com/', '_blank')
              }}
            >
              Customer support
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
