export const StepComponent = ({ index, text1 }) => {
  let title = ''

  switch (index) {
    case 1:
      title = 'About ℹ️'
      break
    case 2:
      title = 'Getting started 🚀'
      break

    case 3:
      title = 'How to 💡'
      break

    case 4:
      title = 'Contact us 📱'
      break

    default:
      break
  }
  return (
    <div style={{ height: '100%' }} className="home-container__powered-by ">
      <div className="d-flex align-items-center p-4 home-container__powered-by__logo">
        <img
          className="d-inline-block me-3"
          alt="Marketo Integrator logo"
          src="/cs-logo-icon@2x.png"
          style={{
            height: '3.5rem',
          }}
        />
        <div className="d-inline-flex flex-column">
          <span
            style={{ fontWeight: 'bold' }}
            className="home-container__powered-by__logo__subheader"
          >
            {title}
          </span>
        </div>
      </div>
      <div className="p-4">
        {index === 4 ? (
          <div className="p-3">
            <div>
              CapabilitySource develops digital marketing strategies and improves marketing
              operations capabilities that support the transformation journey and improve overall
              marketing maturity.
            </div>
            <h5 className="mt-5 d-inline-block">
              <p style={{ height: '13rem' }}></p>
              <b>Contact Us</b>
            </h5>
            <div className="d-flex flex-column">
              <span>CapabilitySource</span>
              <a href="mailto:info@capabilitysource.com">info@capabilitysource.com</a>
              <a href="https://www.capabilitysource.com" target="_blank" rel="noreferrer">
                https://www.capabilitysource.com
              </a>
            </div>
          </div>
        ) : (
          <>
            <div className="p-3">
              {text1.split('\n').map((paragraph) => {
                return <p>{paragraph}</p>
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
