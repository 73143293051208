import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Button } from 'monday-ui-react-core'
import './TOS.scss'
import { StepHeader } from '../StepHeader/StepHeader'
import { useNavigate } from 'react-router-dom'

export const TOS = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <StepHeader />
      </Row>
      <br />
      <br />
      <br />
      <h1>Terms of service</h1>
      <p>
        Terms of Service for our Adobe Marketo and Monday.com Connector Welcome to the Adobe Marketo
        and Monday.com Connector (the "Connector"). By using the Connector, you agree to be bound by
        the following terms and conditions (the "Terms of Service"). If you do not agree to these
        terms, please do not use the Connector. License Subject to these Terms of Service, we grant
        you a limited, non-exclusive, non-transferable, revocable license to use the Connector
        solely for your personal or internal business purposes. You may not use the Connector for
        any other purpose, including but not limited to selling or distributing the Connector or any
        portion thereof. Restrictions You may not reverse engineer, decompile, or disassemble the
        Connector or attempt to access the source code of the Connector. You may not modify, adapt,
        or create derivative works based on the Connector. You may not remove or alter any copyright
        or other proprietary notices contained in the Connector. Warranty Disclaimer The Connector
        is provided on an "as is" and "as available" basis. We make no warranties, express or
        implied, including but not limited to the implied warranties of merchantability, fitness for
        a particular purpose, and non-infringement. We do not warrant that the Connector will be
        error-free or that access to the Connector will be continuous or uninterrupted. Limitation
        of Liability In no event shall we be liable for any damages whatsoever, including but not
        limited to direct, indirect, special, incidental, or consequential damages, arising out of
        or in connection with the use of the Connector. Changes to the Connector and these Terms of
        Service We reserve the right to modify or discontinue the Connector or these Terms of
        Service at any time without notice. It is your responsibility to check these Terms of
        Service periodically for changes. Your continued use of the Connector after the posting of
        any changes to these Terms of Service constitutes acceptance of those changes. Termination
        We reserve the right to terminate your access to the Connector at any time without notice.
        Upon termination, your license to use the Connector will automatically terminate. Governing
        Law These Terms of Service shall be governed by and construed in accordance with the laws of
        the State of Texas, without giving effect to any principles of conflicts of law.
        Miscellaneous These Terms of Service constitute the entire agreement between you and us with
        respect to the Connector. If any provision of these Terms of Service is found to be invalid
        or unenforceable, that provision shall be enforced to the maximum extent possible, and the
        remaining provisions shall remain in full force and effect. Our failure to enforce any right
        or provision of these Terms of Service shall not be deemed a waiver of such right or
        provision. By using the Connector, you represent and warrant that you have read and
        understood these Terms of Service and that you agree to be bound by them. If you have any
        questions about these Terms of Service, please contact us at support@capabilitysource.com.
      </p>
    </Container>
  )
}
