import { Container, Row, Col } from 'react-bootstrap'
import { StepHeader } from '../StepHeader/StepHeader'
import { StepperComponent } from '../StepperComponent/StepperComponent'
import { Button } from 'monday-ui-react-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Check, CloseSmall } from 'monday-ui-react-core/icons'
import { useNavigate } from 'react-router-dom'
import { WorkspaceInitializer } from '../../Components/WorkspaceInitializer/WorkspaceInitializer'
import { useLocalStorage } from '../../Hooks/useLocalStorage'
import { BACKEND_URL } from '../../config'

export const Step4 = () => {
  const navigate = useNavigate()

  const [selectedWorkspace] = useLocalStorage('selectedWorkspace', '')
  const [inProgress, setInProgress] = useState(false)
  const [initCompleted, setInitCompleted] = useState(false)
  const mondayApiKey = localStorage.getItem('MondayApiKey')

  const [currentInitStep, setCurrentInitStep] = useState(1)

  const statuses = useMemo(() => {
    const stats = [
      {
        id: 'init1',
        label: 'Initializing workspace...',
      },
      {
        id: 'init2',
        label: 'Creating email board',
        status: 'done',
      },
      // {
      //   id: 'init3',
      //   label: 'Creating programs board',
      //   status: 'done',
      // },
      {
        id: 'init4',
        label: 'Inserting email templates',
        status: 'done',
      },
      // {
      //   id: 'init5',
      //   label: 'Inserting boards templates',
      //   status: 'done',
      // },
      {
        id: 'init6',
        label: 'Initialization compete',
        status: 'done',
      },
    ]

    if (currentInitStep < stats.length) {
      stats[currentInitStep].status = 'loading'
    }
    if (currentInitStep > 4) {
      setInitCompleted(true)
      stats[0].label = 'Initializing workspace'
      stats[0].status = 'done'
    }
    return stats.slice(0, currentInitStep + 1)
  }, [currentInitStep])

  useEffect(() => {
    const f = (e) => {
      if (e.key === ' ') {
        setCurrentInitStep((c) => c + 1)
      }
    }
    window.addEventListener('keypress', f)

    return () => window.removeEventListener('keypress', f)
  }, [])

  const initializeWorkspace = useCallback(async () => {
    if (!selectedWorkspace?.name) {
      console.error('Unable to find selected workspace')
      return
    }

    const { name: workspaceName, id: workspaceId } = selectedWorkspace
    setInProgress(true)

    try {
      const response1 = await fetch(`${BACKEND_URL}/createEmailJobsBoard`, {
        method: 'POST',
        body: JSON.stringify({
          workspaceName,
          workspaceId,
          auth: {
            mondayApiKey,
          },
        }),
        headers: { 'Content-Type': 'application/json' },
      })
      setCurrentInitStep((s) => s + 1)
      // const response2 = await fetch(`${BACKEND_URL}/createProgramsBoard`, {
      //   method: 'POST',
      //   body: JSON.stringify({
      //     workspaceName,
      //     auth: {
      //       mondayApiKey,
      //     },
      //   }),
      //   headers: { 'Content-Type': 'application/json' },
      // })
      // setCurrentInitStep((s) => s + 1)
      const response3 = await fetch(`${BACKEND_URL}/insertEmailJobsTemplate`, {
        method: 'POST',
        timeout: 36000000,
        body: JSON.stringify({
          workspaceName,
          auth: {
            mondayApiKey,
          },
        }),
        headers: { 'Content-Type': 'application/json' },
      })
      setCurrentInitStep((s) => s + 2)
      // const response4 = await fetch(`${BACKEND_URL}/insertProgramsTemplate`, {
      //   method: 'POST',
      //   body: JSON.stringify({
      //     workspaceName,
      //     auth: {
      //       mondayApiKey,
      //     },
      //   }),
      //   headers: { 'Content-Type': 'application/json' },
      // })
      // setCurrentInitStep((s) => s + 2)
      setInitCompleted(true)
    } catch (error) {
      console.error('Failed to init workspace', error)
    } finally {
      setInProgress(false)
    }
  }, [selectedWorkspace, mondayApiKey])

  const currentStepperStep = useMemo(() => (currentInitStep > 2 ? 5 : 4), [currentInitStep])

  const [apiKeyStatus, setApiKeyStatus] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const inputIcon = useMemo(() => {
    if (apiKeyStatus === 'success') {
      return Check
    }
    if (apiKeyStatus === 'error') {
      return CloseSmall
    }
    return undefined
  }, [apiKeyStatus])

  const statusText = useMemo(() => {
    if (apiKeyStatus === 'success') {
      return 'Success'
    }
    if (apiKeyStatus === 'error') {
      return 'Authentication Error'
    }
    return ''
  }, [apiKeyStatus])

  const submitApiKey = useCallback((e) => {
    let status
    if (e.shiftKey) {
      status = 'error'
    } else {
      status = 'success'
    }

    setApiKeyStatus('')
    setSubmitting(true)

    setTimeout(() => {
      setSubmitting(false)
      setApiKeyStatus(status)
    }, 2000)
  }, [])

  return (
    <Container>
      <Row>
        <StepHeader />
      </Row>
      <Row>
        <Col>
          <StepperComponent currentStep={currentStepperStep} />
        </Col>
      </Row>

      <Row>
        <Col xs={0} md={2} />
        <Col xs={12} md={8}>
          <b>{selectedWorkspace.name}</b>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={0} md={2} />
        <Col xs={12} md={8}>
          {inProgress || initCompleted ? (
            <WorkspaceInitializer statuses={statuses} />
          ) : (
            <Button
              kind={Button.kinds.SECONDARY}
              onClick={() => {
                initializeWorkspace()
              }}
            >
              Initialize Workspace
            </Button>
          )}
        </Col>
        <Col xs={2} className="d-flex align-items-center">
          <span>{statusText}</span>
        </Col>
      </Row>
      <Row className={'mt-5'}>
        <Col xs={10} className={'d-flex justify-content-end'}>
          <Button
            kind={Button.kinds.TERTIARY}
            className={'me-4'}
            disabled={inProgress}
            onClick={() => {
              navigate('/step3')
            }}
          >
            <b>Back</b>
          </Button>
          <Button
            disabled={!initCompleted}
            kind={Button.kinds.SECONDARY}
            onClick={() => navigate('/step3')}
          >
            Initialize another workspace
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
