import { Spinner } from 'react-bootstrap'
import { Icon } from 'monday-ui-react-core'
import { Check } from 'monday-ui-react-core/icons'
import PropTypes from 'prop-types'

import './WorkspaceInitializer.scss'

export const WorkspaceInitializer = ({ statuses }) => {
  return (
    <div className="d-flex flex-column w-100 ws-init p-4">
      {statuses.map((item) => (
        <div
          className={`mx-2 my-1 ws-init__item${item.status === 'loading' ? '--loading' : ''}`}
          key={item.id}
        >
          {item.label}
          {item.status === 'loading' && <Spinner className="ms-2" size="sm" animation="border" />}
          {item.status === 'done' && (
            <div className="text-success d-inline">
              <Icon clickable={false} className="ms-2" icon={Check} />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

WorkspaceInitializer.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      status: PropTypes.oneOf(['loading', 'done', undefined]),
    }),
  ),
}

WorkspaceInitializer.defaultProps = {
  statuses: [],
}
